import React from 'react'
import { graphql } from 'gatsby'
import rehypeReact from 'rehype-react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

import {
  Heading,
  Button,
  Container,
  Hero,
  // Icon,
  Section,
  Box
  // Content,
  // Table
} from 'react-bulma-components'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'

import ArtPortfolioSectionBg from '../components/ArtPortfolioSectionBg'

import { rhythm } from '../utils/typography'
import { Tablet, Mobile } from '../utils/presets'

const renderAst = new rehypeReact({
  createElement: React.createElement
  // components: { 'interactive-counter': Counter }
}).Compiler

class JobPosition extends React.Component {
  getImage(imageName) {
    return this.props.data[imageName].childImageSharp.fluid
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const post = this.props.data.markdownRemark
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        bodyInContainer={false}
        className="index"
      >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          isBlogPost
          postData={post}
          url={this.props.location.href}
        />
        <Hero size="fullheight">
          <Hero.Body>
            <Container>
              <header>
                <Heading
                  size={1}
                  className="custom-heading"
                  css={{
                    marginBottom: rhythm(1),
                    color: post.frontmatter.shadow
                  }}
                >
                  {post.frontmatter.title}
                </Heading>
              </header>
              {/*<div
                dangerouslySetInnerHTML={{ __html: post.tableOfContents }}
                className="toc"
                css={{
                  marginBottom: rhythm(1)
                }}
              />
              */}
              {renderAst(post.htmlAst)}
            </Container>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 300 }}>
              <Container>
                <Section>
                  <Box>
                    <Section
                      className="custom-section"
                      css={{
                        [Mobile]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                        },
                        [Tablet]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingTop: '1rem',
                          paddingBottom: '1rem',
                          flexDirection: 'inherit'
                        }
                      }}
                    >
                      <div>
                        <Heading size={2} renderAs="h2">
                          Let&apos;s talk
                        </Heading>
                        <Heading subtitle size={5} renderAs="h3">
                          Software development solutions that <br /> solve real
                          business needs.
                        </Heading>
                      </div>
                      <Button
                        color="primary"
                        onClick={this.openMailTo}
                        className="responsive-button"
                        css={{
                          display: 'flex',
                          fontWeight: 'bold',
                          [Mobile]: {
                            marginTop: '2rem'
                          },
                          [Tablet]: {
                            marginTop: 0
                          }
                        }}
                      >
                        CONTACT US
                      </Button>
                    </Section>
                  </Box>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
      </Layout>
    )
  }

  decodeSupportURL = () => {
    const encoded = 'ifmmpAubohjcmz/ufdi'
    let link = ''
    for (let i = 0; i < encoded.length; i++)
      link += String.fromCharCode(encoded.charCodeAt(i) - 1)

    return link
  }

  openMailTo = e => {
    e.preventDefault()
    const link = this.decodeSupportURL()

    window.location.href = `mailto:${link}`
  }
}

JobPosition.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}
export default JobPosition

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      htmlAst
      tableOfContents
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
